import { css, useTheme } from '@emotion/react';
import React from 'react';

const MainView: React.FC = ({ children }) => {
  const theme = useTheme();
  return (
    <main
      className="app-main"
      css={css`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        min-height: ${theme.layout.minHeight}px;
        background-color: #e7edf3;
        .main-container {
          display: flex;
          flex-direction: column;
          min-height: calc(100vh - ${theme.layout.headerHeight + theme.layout.footerHeight}px);
          width: 100%;
          color: ${theme.text.blackColor};
          padding-right: 4%;
          padding-bottom: 100px;
          padding-left: 4%;
        }
      `}
    >
      <div className="main-container">{children}</div>
    </main>
  );
};

export default MainView;
