import IconHome from '@/assets/images/home-mls.svg';
import BrokerLandingPageBuilderButton from '@/components/BrokerLandingPageBuilderButton';
import ErrorMessage from '@/components/error-message';
import Hyperlink from '@/components/hyperlink';
import { InnerLoading } from '@/components/loading/loadingBase';
import MailingListSelect from '@/components/select/mailing-list-select';
import MultipleSelect from '@/components/select/multiple-select';
import ButtonAdd from '@/components/smart-action/sm-trigger-add-btn';
import { useQueryParam } from '@/hooks/useQueryParam';
import { BrokerConfigFormType, ShowAddressesMode, SmartActionTabsKey } from '@/models';
import { AddressesSearchMLSParams } from '@/models/AddressesSearchMLSParams';
import MailingAction from '@/reducers/mailing/action';
import { RootState } from '@/reducers/model';
import { SmartActionTriggerParams } from '@/sections/SmartActions/types/SmartActionTab';
import theme from '@/styles/theme';
import {
  _ALL_HOMES_LABEL,
  EMPTY_DATE_DEFAULT,
  avgYearsOwnedMarks,
  bathsMarks,
  bedsMarks,
  domMarks,
  equityMarks,
  interestRateMarks,
  selectedRuleListingFoundBroker,
  sqfeetMarks,
  storyMarks,
  yearsOwnedMarks,
} from '@/utils/constant';
import { KEY_MLS_TRIGGER, KEY_VALUE_TYPE } from '@/utils/enum';
import { formatDate } from '@/utils/format';
import { hashFilterHelper } from '@/utils/query';
import RoutePaths from '@/utils/routes';
import validation from '@/utils/validation';
import { CaretDownOutlined } from '@ant-design/icons';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MailingListTypeEnum } from '@goldfishcode/first-team-real-estate-sdk/libs/api/mailing/models';
import { OperatorEnums } from '@goldfishcode/first-team-real-estate-sdk/libs/api/postalytics/models';
import { Checkbox, DatePicker, Radio, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { getIn } from 'formik';
import { navigate } from 'gatsby';
import { get, isEmpty, omit, pick } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MdAdd, MdOpenInNew, MdOutlineRefresh } from 'react-icons/md';
import { RiErrorWarningLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import ModalBrokerConfiguration from '../modal-broker-configuration';
import { initialValuesBroker } from '../modal-broker-configuration/broker-config-form/BrokerConfigFormContainer';
import MailingAddressSentForms from '../modal-mailing-addresses-sent';
import { DatePickerStyled, ListTag, SelectV2WrapperStyled } from './style';
import { dataHouseProperties, useSmartTriggerForm } from './useSmTrigger';
import AppSelectV2 from '@/components/AppSelectV2';
import { arrayStrToValueLabel, filterOptionLocal } from '@/utils/utils';
import AppInputV2 from '@/components/AppInputV2';
import AppButtonV2 from '@/components/AppButtonV2';

export enum enumAddType {
  'INCLUDE' = 'include',
  'EXCLUDE' = 'exclude',
}

export interface SelectObjType {
  label: string;
  value: string;
}

export interface SmartTriggerFormsProps {
  visible?: boolean;
  onCancel?: () => void;
}
export interface Dropdown {
  value: string;
  label: string;
}
export interface SmartTriggerTypeDropDown {
  mls_status: Array<Dropdown>;
  age_range: Array<string>;
  operators: Array<Dropdown>;
}
const CREATE_MLS_DEFAULT_NAME = 'MLS Default Testing Name';

let timeoutMLS;

const ModalSmartTriggerForm: React.FunctionComponent<SmartTriggerFormsProps> = ({ onCancel, visible }) => {
  const handleCancel = () => {
    mlsFirstLoadParams.current = {} as AddressesSearchMLSParams;
    onCancel && onCancel();
  };
  const checkedMLSQueryParams = useRef(false);
  const queryParams = useQueryParam<SmartActionTriggerParams>();
  const {
    isLoading,
    formik,
    options_operators,
    optionsAudience,
    optionsMilestone,
    optionsDripCampaign,
    handleAddInput,
    renderListProperties,
    options_mls_status,
    // options_age_range,
    options_property_type,
    idEditing,
    isValidPastClient,
    onChangeField,
    isDisabledMLSTrigger,
    searchValueDrip,
    setSearchValueDrip,
    isLoadingDetail,
    handleInfiniteLoad,
    loadingMore,
    isDuplicateItem,
    mlsFirstLoadParams,
    setMailingSelected,
    mailingOptions,
    setMailingOptions,
    visibleModalSmartTrigger,
    handleChangePremiumField,
    formatDataPremium,
    onUpdateBrokerConfiguration,
    options_rea_properties,
    tagList,
    debounceSearchDripCampaign,
    isSelectMyListing,
  } = useSmartTriggerForm({ onCancel: handleCancel });
  const dispatch = useDispatch();
  const [resetAddressesFoundCountFilter, setResetAddressesFoundCountFilter] = useState(false);

  const addressesFoundCountForMLS = useSelector((state: RootState) => state.mailing.addressesFoundCountForMLS);
  const addressesFoundCountLoading = useSelector((state: RootState) => state.mailing.addressesFoundCountLoading);
  const isBrokerStaffTemp = useSelector((state: RootState) => state.auth.myProfile?.is_broker_staff);
  // const isBrokerLoginTemp = useSelector((state: RootState) => state.auth.myProfile?.is_broker_login);

  const isBrokerStaff = isBrokerStaffTemp;
  const hasError = (key: string) => {
    if (
      (key === KEY_MLS_TRIGGER.name && getIn(formik.errors, key) === validation.required) ||
      key === KEY_MLS_TRIGGER.landing_page ||
      key === KEY_MLS_TRIGGER.sm_trigger_campaign_id ||
      key === KEY_MLS_TRIGGER.milestone ||
      key === KEY_MLS_TRIGGER.mls_status ||
      key === KEY_MLS_TRIGGER.postalytics_audience_ids ||
      key === KEY_MLS_TRIGGER.mailing_lists ||
      key === KEY_MLS_TRIGGER.select_rule ||
      key === KEY_MLS_TRIGGER.max_search_homes ||
      key === KEY_MLS_TRIGGER.mls_status_filters
    ) {
      return Object.keys(formik.errors).length > 0 && !!getIn(formik.errors, key) && !!getIn(formik.touched, key);
    }
    return Object.keys(formik.errors).length > 0 && !!getIn(formik.errors, key);
  };

  const onChangeCheckBox = (e: CheckboxChangeEvent, key) => {
    formik.setFieldValue(key, e.target.checked);
  };

  const getValueField = (key, field) => {
    return get(formik.values, `${[key] + '.' + [field]}`);
  };

  const isNotValidSubmitting = () => {
    let validHousePropertiesError = false;
    if (!isEmpty(formik?.errors)) {
      Object.entries(formik?.errors).forEach(([key]) => {
        if (dataHouseProperties[key]) {
          validHousePropertiesError = true;
        }
      });
    }
    return (
      formik.isSubmitting ||
      (!formik.isValid && !validHousePropertiesError) ||
      isLoading ||
      !!(
        !formik.values[KEY_MLS_TRIGGER.mls_status]?.length &&
        !formik.values[KEY_MLS_TRIGGER.milestone]?.length &&
        !formik.values[KEY_MLS_TRIGGER.trigger_on_demand]
      ) ||
      !!(
        !formik.values[KEY_MLS_TRIGGER.mls_status_filters]?.length &&
        !formik.values[KEY_MLS_TRIGGER.trigger_on_demand] &&
        !formik.values.is_broker
      )
    );
  };

  const checkMailingListHasNotChanged = (source, target) => {
    if (source && target && source.length >= 0 && target.length > 0 && source?.length !== target?.length) {
      return false; // Different number of elements, return false
    }

    for (let i = 0; i < source?.length; i++) {
      if (target && source && !target.includes(source[i])) {
        return false; // Value from source not found in target, return false
      }
      if (source && target && !source.includes(target[i])) {
        return false; // Value from target not found in source, return false
      }
    }

    return true; // All values are the same, return true
  };

  useEffect(() => {
    if (timeoutMLS) clearTimeout(timeoutMLS);
    const dataPremium = formatDataPremium(formik.values);
    const smartActionFilterData = hashFilterHelper.decrypt(queryParams.convertMLS);

    let params = {
      ...addressesFoundCountForMLS?.params,
      ...formik.values,
      ...dataPremium,
      mailing_lists: formik.values.mailing_lists,
      smart_trigger_action_id: idEditing ? idEditing : undefined,
    } as AddressesSearchMLSParams;
    if (smartActionFilterData) {
      const { is_realtor, owner_occupied, owner_out_of_state, search: textSearch } = smartActionFilterData;

      const addressFilter = {
        is_realtor,
        owner_occupied,
        owner_out_of_state,
        textSearch,
      };
      params = { ...params, ...addressFilter };
    }
    params = omit(params, Object.keys(initialValuesBroker)) as AddressesSearchMLSParams;

    if (!params.name) params.name = CREATE_MLS_DEFAULT_NAME;

    // Save params at the first time
    if (
      idEditing &&
      ((!Object.keys(mlsFirstLoadParams.current).length &&
        Object.keys(params).length &&
        params.name !== CREATE_MLS_DEFAULT_NAME) ||
        (Object.keys(mlsFirstLoadParams.current).length &&
          Object.keys(params).length &&
          mlsFirstLoadParams.current.smart_trigger_action_id !== params.smart_trigger_action_id))
    ) {
      mlsFirstLoadParams.current = params;
    }

    setResetAddressesFoundCountFilter(false);
    setTimeout(() => setResetAddressesFoundCountFilter(true), 100);
    dispatch({
      type: MailingAction.RESET_ADDRESSES_COUNT_MLS,
    });

    let isMailingListNotChanged =
      smartActionFilterData === undefined
        ? checkMailingListHasNotChanged(idEditing ? mlsFirstLoadParams.current.mailing_lists : [], params.mailing_lists)
        : true;

    // If trigger_on_demand changes, it is considered a change to mailing_lists
    if (
      typeof mlsFirstLoadParams.current.trigger_on_demand === 'boolean' &&
      typeof params.trigger_on_demand === 'boolean' &&
      mlsFirstLoadParams.current.trigger_on_demand !== params.trigger_on_demand
    ) {
      isMailingListNotChanged = false;
    }
    if (params.trigger_on_demand) {
      delete params.select_rule;
      // TODO remove
      delete (params as any).rules;
    }
    params.isMailingListNotChanged = isMailingListNotChanged;

    timeoutMLS = setTimeout(() => {
      if (
        !formik.values.mailing_lists?.length ||
        !params.postalytics_audience_ids?.length ||
        !formik.values.mls_status_filters?.length
        // At least one of them need to be selected
        // (!formik.values.mls_status?.length && !formik.values.milestone?.length)
      ) {
        // Prevent call filter API
        return;
      }
      dispatch({
        type: MailingAction.UPDATE_PARAMS_MAILING_ADDRESS_SENT,
        payload: {
          type: ShowAddressesMode.MLS_TRIGGER,
          params,
        },
      });
    }, 1000);
  }, [
    ...Object.keys(dataHouseProperties)?.map((key) => formik?.values[key]?.exclude),
    ...Object.keys(dataHouseProperties)?.map((key) => formik?.values[key]?.include),
    formik.values.is_active,
    formik.values.is_default,
    formik.values.is_new_past_client,
    formik.values.is_property_empty,
    formik.values.trigger_on_demand,
    formik.values.mls_status_filters,
    formik.values.rea_properties,
    formik.values.is_only_my_listing,
    formik.values.mailing_lists,
    formik.values.postalytics_audience_ids,
    formik.values.interests,
    formik.values.industries,
    formik.values.age,
    formik.values.employers,
    formik.values.postalytics_audience,
    formik.values.tags,
    formik.values[KEY_MLS_TRIGGER.notice_of_default],
    formik.values[KEY_MLS_TRIGGER.divorcees],
    formik.values[KEY_MLS_TRIGGER.affidavits_of_death],
  ]);

  const isEmptyDateValue = (value) => formatDate(value) === formatDate(EMPTY_DATE_DEFAULT);
  const getMaxObjectKey = (object: any) => {
    const keys = Object.keys(object || {});
    const numericKeys = keys.map(Number);
    const max = Math.max(...numericKeys);
    return max;
  };

  const formatRangeNumber = useCallback((value: string) => {
    const arrayValue = value?.split(',');

    const isMinValidNumberic = !['', undefined, null].includes(arrayValue[0]);
    const isMaxValidNumberic = !['', undefined, null].includes(arrayValue[1]);
    const isMinEquaToMax = isMinValidNumberic && isMaxValidNumberic && arrayValue[0] === arrayValue[1];

    let res = {} as any;
    if ((!isMinValidNumberic && !isMaxValidNumberic) || arrayValue?.length !== 2) {
      res = {
        include: [],
        operator: OperatorEnums.EQUAL,
      };
    } else {
      if (isMinEquaToMax) {
        res = {
          include: [
            {
              operator: OperatorEnums.EQUAL,
              value: parseInt(arrayValue[0]),
            },
          ],
          operator: OperatorEnums.EQUAL,
        };
      } else {
        if (!isMinValidNumberic && isMaxValidNumberic) {
          const minVal = 0;
          const maxVal = parseInt(arrayValue[1]);
          const isSameZero = minVal === maxVal;
          res = {
            include: isSameZero
              ? [
                  {
                    operator: OperatorEnums.EQUAL,
                    value: minVal,
                  },
                ]
              : [
                  {
                    operator: OperatorEnums.GREATER_OR_EQUAL,
                    value: minVal,
                  },
                  {
                    operator: OperatorEnums.LOWER_OR_EQUAL,
                    value: maxVal,
                  },
                ],
            operator: OperatorEnums.EQUAL,
          };
        } else if (isMinValidNumberic && !isMaxValidNumberic) {
          res = {
            include: [
              {
                operator: OperatorEnums.GREATER_OR_EQUAL,
                value: parseInt(arrayValue[0]),
              },
            ],
            operator: OperatorEnums.EQUAL,
          };
        } else if (isMinValidNumberic && isMaxValidNumberic) {
          res = {
            include: [
              {
                operator: OperatorEnums.GREATER_OR_EQUAL,
                value: parseInt(arrayValue[0]),
              },
              {
                operator: OperatorEnums.LOWER_OR_EQUAL,
                value: parseInt(arrayValue[1]),
              },
            ],
            operator: OperatorEnums.EQUAL,
          };
        }
      }
    }

    return res;
  }, []);

  const formatSliderValue = useCallback((value: string, maxValue?: number, keys?: string[]) => {
    const arrayValue = value?.split(',');
    let isEqualMaxValue = false;
    if (keys?.includes(KEY_MLS_TRIGGER.interest_rate) || keys?.includes(KEY_MLS_TRIGGER.home_equity_percentage)) {
      isEqualMaxValue = true;
    }
    let res = {} as any;
    if (arrayValue?.length === 2) {
      if (arrayValue[0] === arrayValue[1]) {
        res = {
          include: [
            {
              operator:
                Number(arrayValue[0]) === maxValue && !isEqualMaxValue
                  ? OperatorEnums.GREATER_OR_EQUAL
                  : OperatorEnums.EQUAL,
              value: parseInt(Math.min(...value?.split(',').map(Number)).toString()),
            },
          ],
          operator: OperatorEnums.EQUAL,
        };
      } else if (Number(arrayValue[1]) === maxValue) {
        res = {
          include: [
            {
              operator: OperatorEnums.GREATER_OR_EQUAL,
              value: parseInt(Math.min(...value?.split(',').map(Number)).toString()),
            },
          ],
          operator: OperatorEnums.EQUAL,
        };
      } else {
        res = {
          include: [
            {
              operator: OperatorEnums.GREATER_OR_EQUAL,
              value: parseInt(Math.min(...value?.split(',').map(Number)).toString()),
            },
            {
              operator: OperatorEnums.LOWER_OR_EQUAL,
              value: parseInt(Math.max(...value?.split(',').map(Number)).toString()),
            },
          ],
          operator: OperatorEnums.EQUAL,
        };
      }
    }
    return res;
  }, []);
  useEffect(() => {
    if (
      !visible ||
      !optionsAudience?.length ||
      !mailingOptions?.length ||
      !queryParams.convertMLS ||
      checkedMLSQueryParams.current
    )
      return;

    checkedMLSQueryParams.current = true;
    const smartActionFilterData = hashFilterHelper.decrypt(queryParams.convertMLS);
    if (!smartActionFilterData || !Object.keys(smartActionFilterData).length) return;

    const {
      mailing_list_ids,
      has_premium,
      mls_status,
      milestones,
      audience_ids,
      employers,
      interests,
      estimated_price_value,
      estimated_price_operator,
      notice_of_default_value,
      divorcees_value,
      divorcees_operator,
      affidavits_of_death_value,
      notice_of_default_operator,
      affidavits_of_death_operator,
      bathrooms,
      avg_years_owned,
      bedrooms,
      sqft,
      home_equity_percentage,
      stories,
      is_realtor,
      owner_occupied,
      owner_out_of_state,
      rea_properties,
      tags,
      years_owned,
      avm_values: avm_value,
      interest_rates,
      days_on_market,
      price_per_square_foot,
      myListingMailingListId,
      zip_codes,
    } = smartActionFilterData;

    const mailingListIds =
      mailing_list_ids?.length > 0
        ? mailing_list_ids?.length > 1
          ? mailing_list_ids.filter((v) => v !== myListingMailingListId)
          : mailing_list_ids
        : mailingOptions.length > 0
        ? mailingOptions.length > 1
          ? mailingOptions.filter((v) => v.type !== MailingListTypeEnum.My_Listing).map((v) => v.id)
          : mailingOptions.map((v) => v.id)
        : formik.values.mailing_lists;
    let newValues = {
      ...formik.values,
      mailing_lists: mailingListIds,
      mls_status: mls_status ? mls_status?.split(',') : formik.values.mls_status,
      mls_status_filters: mls_status ? mls_status?.split(',') : formik.values.mls_status_filters,
      rea_properties: rea_properties ? rea_properties?.split(',') : formik.values.rea_properties,
      tags: tags ? tags?.split(',') : formik.values.tags,
      milestone: mls_status ? undefined : milestones ? milestones?.split(',') : formik.values.milestones, // Priority for mls_status
      postalytics_audience_ids: audience_ids
        ? audience_ids?.split(',') // Get the first option
        : optionsAudience.filter((item) => item?.label === _ALL_HOMES_LABEL).map((item) => item.value) ||
          formik.values.postalytics_audience_ids, // All Homes for default
      employers: employers ? { checked: true, value: employers.split(',') } : formik.values.employers,
      interests: interests ? { checked: true, value: interests.split(',') } : formik.values.interests,
      is_realtor: is_realtor,
      owner_occupied: owner_occupied,
      owner_out_of_state: owner_out_of_state,
      //premium
      [KEY_MLS_TRIGGER.estimated_price]: estimated_price_value
        ? {
            include: [{ operator: estimated_price_operator, value: estimated_price_value }],
            operator: OperatorEnums.EQUAL,
          }
        : formik.values[KEY_MLS_TRIGGER.estimated_price],

      [KEY_MLS_TRIGGER.notice_of_default]: notice_of_default_value
        ? {
            checked: true,
            operator: isEmptyDateValue(notice_of_default_value) ? OperatorEnums.EQUAL : notice_of_default_operator,
            value: isEmptyDateValue(notice_of_default_value) ? '' : moment(notice_of_default_value),
          }
        : formik.values[KEY_MLS_TRIGGER.notice_of_default],
      [KEY_MLS_TRIGGER.divorcees]: divorcees_value
        ? {
            checked: true,
            operator: isEmptyDateValue(divorcees_value) ? OperatorEnums.EQUAL : divorcees_operator,
            value: isEmptyDateValue(divorcees_value) ? '' : moment(divorcees_value),
          }
        : formik.values[KEY_MLS_TRIGGER.divorcees],
      [KEY_MLS_TRIGGER.affidavits_of_death]: affidavits_of_death_value
        ? {
            checked: true,
            operator: isEmptyDateValue(affidavits_of_death_value) ? OperatorEnums.EQUAL : affidavits_of_death_operator,
            value: isEmptyDateValue(affidavits_of_death_value) ? '' : moment(affidavits_of_death_value),
          }
        : formik.values[KEY_MLS_TRIGGER.affidavits_of_death],

      //slider
      [KEY_MLS_TRIGGER.num_of_bathrooms]: bathrooms
        ? formatSliderValue(bathrooms, getMaxObjectKey(bathsMarks))
        : formik.values[KEY_MLS_TRIGGER.num_of_bathrooms],
      [KEY_MLS_TRIGGER.num_of_bedrooms]: bedrooms
        ? formatSliderValue(bedrooms, getMaxObjectKey(bedsMarks))
        : formik.values[KEY_MLS_TRIGGER.num_of_bedrooms],
      [KEY_MLS_TRIGGER.square_feet]: sqft
        ? formatSliderValue(sqft, getMaxObjectKey(sqfeetMarks))
        : formik.values[KEY_MLS_TRIGGER.square_feet],
      [KEY_MLS_TRIGGER.home_equity_percentage]: home_equity_percentage
        ? formatSliderValue(home_equity_percentage, getMaxObjectKey(equityMarks))
        : formik.values[KEY_MLS_TRIGGER.home_equity_percentage],
      [KEY_MLS_TRIGGER.avg_year_owned]: avg_years_owned
        ? formatSliderValue(avg_years_owned, getMaxObjectKey(avgYearsOwnedMarks))
        : formik.values[KEY_MLS_TRIGGER.avg_year_owned],
      [KEY_MLS_TRIGGER.years_owned]: years_owned
        ? formatSliderValue(years_owned, getMaxObjectKey(yearsOwnedMarks))
        : formik.values[KEY_MLS_TRIGGER.years_owned],
      [KEY_MLS_TRIGGER.avm_value]: avm_value ? formatRangeNumber(avm_value) : formik.values[KEY_MLS_TRIGGER.avm_value],
      [KEY_MLS_TRIGGER.stories]: stories
        ? formatSliderValue(stories, getMaxObjectKey(storyMarks))
        : formik.values[KEY_MLS_TRIGGER.stories],
      [KEY_MLS_TRIGGER.interest_rate]: interest_rates
        ? formatSliderValue(interest_rates, getMaxObjectKey(interestRateMarks), [
            KEY_MLS_TRIGGER.interest_rate,
            KEY_MLS_TRIGGER.home_equity_percentage,
          ])
        : formik.values[KEY_MLS_TRIGGER.interest_rate],
      [KEY_MLS_TRIGGER.price_per_square_foot]: price_per_square_foot
        ? formatRangeNumber(price_per_square_foot)
        : formik.values[KEY_MLS_TRIGGER.price_per_square_foot],
      [KEY_MLS_TRIGGER.days_on_market]: days_on_market
        ? formatSliderValue(days_on_market, getMaxObjectKey(domMarks))
        : formik.values[KEY_MLS_TRIGGER.days_on_market],
      [KEY_MLS_TRIGGER.zip_code]: {
        [enumAddType.INCLUDE]: Array.isArray(zip_codes) ? zip_codes.map((zip) => ({ value: zip })) : undefined,
      },
    };
    if (has_premium) {
      newValues = {
        ...newValues,
        [KEY_MLS_TRIGGER.notice_of_default]: {
          ...newValues[KEY_MLS_TRIGGER.notice_of_default],
          checked: true,
        },
        [KEY_MLS_TRIGGER.divorcees]: {
          ...newValues[KEY_MLS_TRIGGER.divorcees],
          checked: true,
        },
        [KEY_MLS_TRIGGER.affidavits_of_death]: {
          ...newValues[KEY_MLS_TRIGGER.affidavits_of_death],
          checked: true,
        },
      };
    }

    formik.setValues(newValues);
    navigate(`${RoutePaths.SMART_ACTIONS}/?tab=${SmartActionTabsKey.SMART_TRIGGERS}`, { replace: true });
  }, [queryParams, optionsAudience, mailingOptions]);

  useEffect(() => {
    if (!isSelectMyListing) {
      formik.setFieldValue(KEY_MLS_TRIGGER.select_rule, undefined);
      formik.setFieldValue(KEY_MLS_TRIGGER.max_search_homes, undefined);

      formik.setErrors({
        ...formik.errors,
        [KEY_MLS_TRIGGER.select_rule]: undefined,
        [KEY_MLS_TRIGGER.max_search_homes]: undefined,
      });

      formik.setTouched({
        ...formik.touched,
        [KEY_MLS_TRIGGER.select_rule]: undefined,
        [KEY_MLS_TRIGGER.max_search_homes]: undefined,
      });
    } else {
      if (!formik.values.trigger_on_demand && formik.values.milestone) {
        onChangeField(KEY_MLS_TRIGGER.milestone, null, undefined);
      }
    }
  }, [isSelectMyListing]);

  return (
    <InnerLoading spinning={isLoadingDetail || !!queryParams.convertMLS} iconStyle={{ color: '#fff' }}>
      <div className="content">
        <div className="section-modal">
          <div className="wrapper-modal">
            <p className="title"> Smart Trigger {isDuplicateItem ? 'Duplicate' : idEditing ? 'Edit' : 'Details'}</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="field_item">
                {isBrokerStaff && (
                  <div className="form-grid">
                    <div className="form-item-half"></div>
                    <div className="form-item-half broker">
                      <SwitchStyled className="switch-group">
                        <Radio.Group value={!!formik.values.is_broker} size="large">
                          <Radio.Button
                            value={false}
                            onChange={(e) => {
                              formik.setFieldValue('is_broker', e.target.value);
                            }}
                          >
                            Default
                          </Radio.Button>
                          <Radio.Button
                            value={true}
                            onChange={(e) => {
                              formik.setFieldValue('is_broker', e.target.value);
                            }}
                          >
                            Broker
                          </Radio.Button>
                        </Radio.Group>
                      </SwitchStyled>
                    </div>
                    <div className="form-item-half"></div>
                  </div>
                )}
                <div className="form-grid">
                  {(!isBrokerStaff || !formik?.values?.is_broker) && <div className="form-item-half" />}
                  <div className="form-item-half">
                    <AppInputV2
                      className="field"
                      label="Smart Trigger Name"
                      name="name"
                      type="text"
                      size="large"
                      placeholder="Enter Smart Trigger Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      hasError={hasError('name')}
                      requiredMark
                      disabled={addressesFoundCountLoading || formik.isSubmitting}
                      errorMessage={formik.errors.name}
                    />
                  </div>
                  {(!isBrokerStaff || !formik?.values?.is_broker) && <div className="form-item-half" />}
                  {isBrokerStaff && formik?.values?.is_broker ? (
                    <>
                      <div className="form-item-half">
                        <ModalBrokerConfiguration
                          onUpdateBrokerConfiguration={onUpdateBrokerConfiguration}
                          brokerData={pick(formik.values, Object.keys(initialValuesBroker)) as BrokerConfigFormType}
                        >
                          <AppButtonV2
                            color="Pin"
                            size="large"
                            width="100%"
                            uppercase={false}
                            fontSize={14}
                            style={{ marginTop: 30 }}
                          >
                            Automated Broker Configuration
                          </AppButtonV2>
                        </ModalBrokerConfiguration>
                        {hasError('automation_broker_landing_page') ? (
                          <div className="noti-broker">
                            <RiErrorWarningLine size={19} color="#ff00dd" style={{ marginRight: '5px' }} />
                            <ErrorMessage className="form-item-error" color={'#fff'}>
                              {formik.errors.automation_broker_landing_page}
                            </ErrorMessage>
                          </div>
                        ) : null}
                      </div>
                      <div className="form-item-half">
                        <BrokerLandingPageBuilderButton />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {/* start Triggers  */}
              <div className="field_item">
                <div className="title">
                  <h2>Triggers</h2>
                  <p>There are multiple selections available for all dropdowns below.</p>
                </div>
                <div className="form-flex">
                  <div className="form-item-half form-item-flex" id="market">
                    <MultipleSelect
                      css={css`
                        width: 375px;
                      `}
                      tooltipText={
                        formik.values.trigger_on_demand ? 'This trigger only support for non-instant send case' : ''
                      }
                      className={`field base-field ${
                        formik.values.trigger_on_demand || isDisabledMLSTrigger('mls_status') ? 'disabled' : ''
                      }`}
                      label="MLS Status"
                      requiredMark={!formik.values?.trigger_on_demand}
                      itemSelect={options_mls_status}
                      handleBlur={formik.handleBlur}
                      value={formik.values.mls_status}
                      handleChange={(value) => {
                        onChangeField('mls_status', null, value);
                      }}
                      name="mls_status"
                      placeholder={'Select MLS Status'}
                      disabled={
                        addressesFoundCountLoading ||
                        formik.isSubmitting ||
                        formik.values.trigger_on_demand ||
                        isDisabledMLSTrigger('mls_status')
                      }
                      filterOption={(input, option) => {
                        return (
                          option?.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                          option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                      allowClear
                      errorMessage={formik.errors.mls_status}
                      hasError={hasError('mls_status')}
                    />
                  </div>
                  <div className="form-item-half form-item-flex" id="market">
                    <MultipleSelect
                      css={css`
                        width: 375px;
                      `}
                      className={`field base-field ${
                        isDisabledMLSTrigger('milestone') || (!formik.values.trigger_on_demand && isSelectMyListing)
                          ? 'disabled'
                          : ''
                      }`}
                      label="Life Events"
                      placeholder="Select Life Events"
                      name="milestone"
                      requiredMark={!formik.values?.trigger_on_demand}
                      type="string"
                      itemSelect={optionsMilestone}
                      handleChange={(value) => onChangeField('milestone', null, value)}
                      handleBlur={formik.handleBlur}
                      value={formik.values.milestone}
                      disabled={
                        addressesFoundCountLoading ||
                        formik.isSubmitting ||
                        isDisabledMLSTrigger('milestone') ||
                        (!formik.values.trigger_on_demand && isSelectMyListing)
                      }
                      allowClear
                      hasError={hasError('milestone')}
                      errorMessage={formik.errors.milestone}
                    />
                  </div>
                </div>
                <div className="input-trigger">
                  {' '}
                  <div
                    className={`form-item-half f-checkbox mt-3x full-w ${formik.values.is_broker ? 'disabled' : ''}`}
                  >
                    <Checkbox
                      disabled={addressesFoundCountLoading || formik.values.is_broker}
                      checked={formik.values?.trigger_on_demand}
                      onChange={(e) => {
                        onChangeField('mailing_lists', null, []);
                        onChangeCheckBox(e, 'trigger_on_demand');
                        formik.setFieldValue(KEY_MLS_TRIGGER.select_rule, undefined);
                      }}
                    >
                      MLS Status Instant Send
                    </Checkbox>
                  </div>
                </div>
              </div>
              {/* end Triggers  */}
              {/* start Filters  */}
              <div className="field_item">
                <div className="title">
                  <h2>Filters</h2>
                </div>
                <div className="form-grid four-col">
                  <div className={`form-item-half  ${formik.values.is_broker ? 'disabled' : ''}`} id="parentId">
                    <MailingListSelect
                      className="field base-field"
                      label="Mailing Lists"
                      mode="multiple"
                      placeholder="Select Mailing List"
                      name="mailing_lists"
                      handleChange={(value, options) => {
                        onChangeField('mailing_lists', null, value);
                        setMailingSelected(options as SelectObjType[]);
                      }}
                      value={formik.values?.mailing_lists}
                      requiredMark={!formik.values.is_broker}
                      suffixIcon={<CaretDownOutlined />}
                      isGetContainer
                      isTrigger={visibleModalSmartTrigger}
                      disabled={formik.values.is_broker}
                      mailingListType={MailingListTypeEnum.All}
                      isDisableIfSelectMyListing={!formik.values?.trigger_on_demand}
                      onOptionsLoaded={(options) => setMailingOptions(options)}
                      errorMessage={formik.errors?.mailing_lists}
                      hasError={hasError('mailing_lists')}
                    />
                  </div>
                  <div className={`form-item-half  ${formik.values.is_broker ? 'disabled' : ''}`} id="pin-status">
                    <MultipleSelect
                      parentId="pin-status"
                      className="field base-field"
                      label="MLS Status"
                      requiredMark={!formik.values.is_broker}
                      itemSelect={options_mls_status}
                      handleBlur={formik.handleBlur}
                      value={formik.values.mls_status_filters}
                      handleChange={(value) => {
                        onChangeField('mls_status_filters', null, value);
                      }}
                      name="mls_status_filters"
                      placeholder={'Select MLS Status'}
                      disabled={addressesFoundCountLoading || formik.isSubmitting || formik.values.is_broker}
                      filterOption={(input, option) => {
                        return (
                          option?.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                          option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                      allowClear
                      errorMessage={formik.errors?.mls_status_filters}
                      hasError={hasError('mls_status_filters')}
                    />
                  </div>
                  {!formik.values?.trigger_on_demand && isSelectMyListing ? (
                    <>
                      <div className="form-item-half" id="rule">
                        <AppSelectV2
                          className="field item-field"
                          requiredMark
                          label="Select Rule"
                          placeholder="Select Rule"
                          options={selectedRuleListingFoundBroker}
                          onChange={(value) => formik.setFieldValue(KEY_MLS_TRIGGER.select_rule, value)}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.[KEY_MLS_TRIGGER.select_rule]}
                          hasError={hasError(KEY_MLS_TRIGGER.select_rule)}
                          errorMessage={formik.errors?.[KEY_MLS_TRIGGER.select_rule]}
                        />
                      </div>
                      <div className="form-item-half">
                        <AppInputV2
                          label="Number of homes to target"
                          className="field sm-field val-field"
                          requiredMark
                          name={KEY_MLS_TRIGGER.max_search_homes}
                          type="text"
                          size="large"
                          placeholder="Enter Number"
                          onChange={(e) => {
                            formik.setFieldValue(KEY_MLS_TRIGGER.max_search_homes, e.target.value);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values[KEY_MLS_TRIGGER.max_search_homes]}
                          hasError={hasError(KEY_MLS_TRIGGER.max_search_homes)}
                          errorMessage={formik.errors[KEY_MLS_TRIGGER.max_search_homes]}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="form-item-half" id="market">
                        <MultipleSelect
                          className={`field base-field`}
                          name="postalytics_audience_ids"
                          label="Audience"
                          requiredMark
                          itemSelect={optionsAudience}
                          handleBlur={formik.handleBlur}
                          value={formik.values.postalytics_audience_ids}
                          handleChange={(value) => {
                            onChangeField('postalytics_audience_ids', null, value);
                          }}
                          placeholder="Select Audience"
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          filterOption={filterOptionLocal}
                          allowClear
                          errorMessage={formik.errors?.postalytics_audience_ids}
                          hasError={hasError('postalytics_audience_ids')}
                        />
                      </div>
                      <div className="form-item-half flex">
                        <div
                          css={css`
                            display: flex;
                            width: 100%;
                            justify-content: flex-end;
                            align-items: flex-end;
                            flex-direction: column;
                          `}
                        >
                          <div
                            css={css`
                              height: 15.14px;
                              width: 30px;
                            `}
                          ></div>
                          <AppButtonV2
                            size="large"
                            color="Pin"
                            width={278}
                            className="btn-view-addresses"
                            disabled={
                              formik.values.is_broker ||
                              addressesFoundCountLoading ||
                              (addressesFoundCountForMLS?.params &&
                                !Object.keys(addressesFoundCountForMLS.params).length)
                            }
                            onClick={() => {
                              dispatch({
                                type: MailingAction.OPEN_MAILING_ADDRESS_POPUP,
                                payload: true,
                              });
                            }}
                            uppercase={false}
                            fontSize={13}
                            style={{ marginTop: 16 }}
                          >
                            <img src={IconHome} alt="" />
                            &nbsp;Select/Deselect Addresses&nbsp;
                            {addressesFoundCountLoading ? (
                              <InnerLoading
                                iconStyle={{
                                  color: '#fff',
                                }}
                                size="small"
                              />
                            ) : Object.keys(addressesFoundCountForMLS?.params || {}).length > 0 &&
                              addressesFoundCountForMLS &&
                              addressesFoundCountForMLS?.updatedCount >= 0 ? (
                              `(${addressesFoundCountForMLS?.updatedCount}/${
                                addressesFoundCountForMLS?.mailings.filter((v) => !v.isDuplicated)?.length
                              })`
                            ) : (
                              ''
                            )}
                          </AppButtonV2>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="form-grid" style={{ marginTop: 10 }}>
                  <div className={`form-item-half f-checkbox flex-start ${!isValidPastClient() ? 'disabled' : ''}`}>
                    <Checkbox
                      disabled={addressesFoundCountLoading || !isValidPastClient()}
                      checked={formik.values?.is_new_past_client}
                      onChange={(e) => onChangeCheckBox(e, 'is_new_past_client')}
                    >
                      New Past Client
                    </Checkbox>
                  </div>
                  <div className="form-item-half"></div>
                  {!formik.values?.trigger_on_demand && isSelectMyListing ? null : (
                    <div className="form-item-half f-checkbox">
                      <Checkbox
                        disabled={addressesFoundCountLoading}
                        checked={formik.values?.is_only_my_listing}
                        onChange={(e) => onChangeCheckBox(e, 'is_only_my_listing')}
                      >
                        My Listings Only
                      </Checkbox>
                    </div>
                  )}
                </div>
                <div className="form-grid">
                  <div className="form-item-half">
                    <MultipleSelect
                      className={`field base-field`}
                      name="rea_properties"
                      label="Property Characteristics"
                      itemSelect={options_rea_properties}
                      handleBlur={formik.handleBlur}
                      value={formik.values.rea_properties}
                      handleChange={(value) => {
                        onChangeField('rea_properties', null, value);
                      }}
                      placeholder="Select Property Characteristics"
                      disabled={addressesFoundCountLoading || formik.isSubmitting}
                      filterOption={(input, option) => {
                        return (
                          option?.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                          option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                      allowClear
                      errorMessage={formik.errors?.rea_properties}
                      hasError={hasError('rea_properties')}
                    />
                  </div>
                  <div className="form-item-half">
                    <MultipleSelect
                      className={`field base-field`}
                      name="tags"
                      label="Custom Tags"
                      itemSelect={tagList}
                      handleBlur={formik.handleBlur}
                      value={formik.values.tags}
                      handleChange={(value) => {
                        onChangeField('tags', null, value);
                      }}
                      placeholder="Select Custom Tags"
                      disabled={addressesFoundCountLoading || formik.isSubmitting}
                      filterOption={(input, option) => {
                        return (
                          option?.props?.label?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0 ||
                          option?.props?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                        );
                      }}
                      allowClear
                      errorMessage={formik.errors?.tags}
                      hasError={hasError('tags')}
                    />
                  </div>
                </div>
                {/* start Premium Data*/}

                <div className="title mrt">
                  <p>Check the boxes below for each Premium Data you want to add to the Smart Trigger.</p>
                </div>
                <div className="form-grid">
                  <div className="form-item-half flex">
                    <div>
                      <Checkbox
                        checked={formik.values[KEY_MLS_TRIGGER.notice_of_default]?.checked}
                        onChange={(e) =>
                          handleChangePremiumField(
                            KEY_MLS_TRIGGER.notice_of_default,
                            KEY_VALUE_TYPE.checked,
                            e.target.checked,
                          )
                        }
                      />
                    </div>

                    <div
                      className={`flex-col ${
                        !formik.values[KEY_MLS_TRIGGER.notice_of_default]?.checked ? 'disabled' : ''
                      }`}
                    >
                      <label className="s-label">Notice Of Default</label>
                      <div className="flex gr-field" id="market">
                        <SelectV2WrapperStyled>
                          <AppSelectV2
                            className="field item-field cal-field"
                            options={options_operators(false).map((f) => ({
                              value: f,
                              label: f,
                            }))}
                            onChange={(value) =>
                              handleChangePremiumField(
                                KEY_MLS_TRIGGER.notice_of_default,
                                KEY_VALUE_TYPE.operator,
                                value,
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values[KEY_MLS_TRIGGER.notice_of_default].operator}
                            disabled={formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.notice_of_default]?.checked}
                          />
                        </SelectV2WrapperStyled>

                        <DatePickerStyled
                          isError={hasError(KEY_MLS_TRIGGER.notice_of_default)}
                          size="large"
                          name={KEY_MLS_TRIGGER.notice_of_default}
                          placeholder="MM/DD/YYYY"
                          value={
                            formik?.values[KEY_MLS_TRIGGER.notice_of_default]?.value === ''
                              ? ''
                              : (moment(formik?.values[KEY_MLS_TRIGGER.notice_of_default]?.value) as any)
                          }
                          onChange={(date) =>
                            handleChangePremiumField(KEY_MLS_TRIGGER.notice_of_default, KEY_VALUE_TYPE.value, date)
                          }
                          format={formatDate}
                          disabled={formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.notice_of_default]?.checked}
                          getPopupContainer={() => document.getElementById('market') as HTMLElement}
                        />
                      </div>
                      {hasError(KEY_MLS_TRIGGER.notice_of_default) ? (
                        <ErrorMessage className="form-item-error">
                          {formik.errors[KEY_MLS_TRIGGER.notice_of_default]?.value}
                        </ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div>
                      <Checkbox
                        checked={formik.values[KEY_MLS_TRIGGER.divorcees]?.checked}
                        onChange={(e) =>
                          handleChangePremiumField(KEY_MLS_TRIGGER.divorcees, KEY_VALUE_TYPE.checked, e.target.checked)
                        }
                      />
                    </div>

                    <div className={`flex-col ${!formik.values[KEY_MLS_TRIGGER.divorcees]?.checked ? 'disabled' : ''}`}>
                      <label className="s-label">Divorcees</label>
                      <div className="flex gr-field" id="market">
                        <SelectV2WrapperStyled>
                          <AppSelectV2
                            className="field item-field cal-field mr"
                            options={options_operators(false).map((f) => ({
                              value: f,
                              label: f,
                            }))}
                            onChange={(value) =>
                              handleChangePremiumField(KEY_MLS_TRIGGER.divorcees, KEY_VALUE_TYPE.operator, value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values[KEY_MLS_TRIGGER.divorcees].operator}
                            disabled={formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.divorcees]?.checked}
                          />
                        </SelectV2WrapperStyled>
                        <DatePickerStyled
                          isError={hasError(KEY_MLS_TRIGGER.divorcees)}
                          size="large"
                          name={KEY_MLS_TRIGGER.divorcees}
                          placeholder="MM/DD/YYYY"
                          value={
                            formik?.values[KEY_MLS_TRIGGER.divorcees]?.value === ''
                              ? ''
                              : (moment(formik?.values[KEY_MLS_TRIGGER.divorcees]?.value) as any)
                          }
                          onChange={(date) =>
                            handleChangePremiumField(KEY_MLS_TRIGGER.divorcees, KEY_VALUE_TYPE.value, date)
                          }
                          format={formatDate}
                          disabled={formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.divorcees]?.checked}
                          getPopupContainer={() => document.getElementById('market') as HTMLElement}
                        />
                      </div>
                      {hasError(KEY_MLS_TRIGGER.divorcees) ? (
                        <ErrorMessage className="form-item-error">
                          {formik.errors[KEY_MLS_TRIGGER.divorcees]?.value}
                        </ErrorMessage>
                      ) : null}
                    </div>
                  </div>

                  <div className="form-item-half flex">
                    <div>
                      <Checkbox
                        checked={formik.values[KEY_MLS_TRIGGER.affidavits_of_death]?.checked}
                        onChange={(e) =>
                          handleChangePremiumField(
                            KEY_MLS_TRIGGER.affidavits_of_death,
                            KEY_VALUE_TYPE.checked,
                            e.target.checked,
                          )
                        }
                      />
                    </div>

                    <div
                      className={`flex-col ${
                        !formik.values[KEY_MLS_TRIGGER.affidavits_of_death]?.checked ? 'disabled' : ''
                      }`}
                    >
                      <label className="s-label">Affidavits of Death</label>
                      <div className="flex gr-field" id="market">
                        <SelectV2WrapperStyled>
                          <AppSelectV2
                            className="field item-field cal-field mr"
                            options={options_operators(false).map((f) => ({
                              value: f,
                              label: f,
                            }))}
                            onChange={(value) =>
                              handleChangePremiumField(
                                KEY_MLS_TRIGGER.affidavits_of_death,
                                KEY_VALUE_TYPE.operator,
                                value,
                              )
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values[KEY_MLS_TRIGGER.affidavits_of_death].operator}
                            disabled={
                              formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.affidavits_of_death]?.checked
                            }
                          />
                        </SelectV2WrapperStyled>
                        <DatePickerStyled
                          isError={hasError(KEY_MLS_TRIGGER.affidavits_of_death)}
                          size="large"
                          name={KEY_MLS_TRIGGER.affidavits_of_death}
                          placeholder="MM/DD/YYYY"
                          value={
                            formik?.values[KEY_MLS_TRIGGER.affidavits_of_death].value === ''
                              ? ''
                              : (moment(formik?.values[KEY_MLS_TRIGGER.affidavits_of_death].value) as any)
                          }
                          onChange={(date) =>
                            handleChangePremiumField(KEY_MLS_TRIGGER.affidavits_of_death, KEY_VALUE_TYPE.value, date)
                          }
                          format={formatDate}
                          disabled={formik.isSubmitting || !formik.values[KEY_MLS_TRIGGER.affidavits_of_death]?.checked}
                          getPopupContainer={() => document.getElementById('market') as HTMLElement}
                        />
                      </div>
                      {hasError(KEY_MLS_TRIGGER.affidavits_of_death) ? (
                        <ErrorMessage className="form-item-error">
                          {formik.errors[KEY_MLS_TRIGGER.affidavits_of_death]?.value}
                        </ErrorMessage>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* end Premium Data*/}
              </div>
              {/* end Filters  */}
              {/* start House Properties  */}
              <div className="field_item pb-80">
                <div className="title">
                  <h2>House Properties</h2>
                  <p className="mb-35">
                    Fill out the boxes below and select a button for each house property you want to have included or
                    excluded from the Smart Trigger.
                  </p>
                </div>
                {renderListProperties()?.length ? (
                  <div className="current-house">
                    <div className="title-tag">Current House Properties:</div>
                    <ListTag>{renderListProperties()}</ListTag>
                  </div>
                ) : null}
                <div className="form-grid">
                  <div className="form-item-half flex">
                    <div className="gr-unit">
                      <div className="flex-col">
                        <div className="w-full">
                          <label className="s-label">Equity</label>
                          <div className="flex gr-field" id="market">
                            <SelectV2WrapperStyled>
                              <AppSelectV2
                                className="field item-field cal-field mr"
                                options={options_operators().map((f) => ({
                                  value: f,
                                  label: f,
                                }))}
                                onChange={(value) =>
                                  onChangeField(KEY_MLS_TRIGGER.home_equity_percentage, 'operator', value)
                                }
                                onBlur={formik.handleBlur}
                                value={formik.values.home_equity_percentage?.operator}
                                disabled={addressesFoundCountLoading}
                              />
                            </SelectV2WrapperStyled>
                            <AppInputV2
                              className="field sm-field val-field"
                              name="home_equity_percentage"
                              type="text"
                              size="large"
                              placeholder="Enter Percentage"
                              onChange={(e) =>
                                onChangeField(KEY_MLS_TRIGGER.home_equity_percentage, 'temp_value', e.target.value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.home_equity_percentage?.temp_value}
                              hasError={hasError(KEY_MLS_TRIGGER.home_equity_percentage)}
                              errorMessage={formik.errors.home_equity_percentage?.temp_value}
                              disabled={addressesFoundCountLoading || formik.isSubmitting}
                              maxLength={10}
                              suffix="%"
                            />
                          </div>
                        </div>
                        <ButtonAdd
                          disabled={addressesFoundCountLoading}
                          handleIncludeBtn={() =>
                            handleAddInput(KEY_MLS_TRIGGER.home_equity_percentage, 'temp_value', enumAddType.INCLUDE)
                          }
                          handleExcludeBtn={() =>
                            handleAddInput(KEY_MLS_TRIGGER.home_equity_percentage, 'temp_value', enumAddType.EXCLUDE)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field w-full"
                          label="City"
                          name={'city'}
                          type="text"
                          placeholder="Enter City"
                          size="large"
                          onBlur={formik.handleBlur}
                          onChange={(e) => onChangeField(KEY_MLS_TRIGGER.city, 'temp_value', e)}
                          value={formik.values?.city?.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.city)}
                          errorMessage={formik.errors?.city?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          maxLength={250}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() => handleAddInput(KEY_MLS_TRIGGER.city, 'temp_value', enumAddType.INCLUDE)}
                        handleExcludeBtn={() => handleAddInput(KEY_MLS_TRIGGER.city, 'temp_value', enumAddType.EXCLUDE)}
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field"
                          label="Zip Code"
                          name={KEY_MLS_TRIGGER.zip_code}
                          type="text"
                          size="large"
                          placeholder="Enter Zip Code"
                          onChange={(e) => onChangeField(`${KEY_MLS_TRIGGER.zip_code}`, 'temp_value', e)}
                          value={formik.values?.[KEY_MLS_TRIGGER.zip_code].temp_value}
                          onBlur={formik.handleBlur}
                          hasError={hasError(`${KEY_MLS_TRIGGER.zip_code}`)}
                          errorMessage={formik.errors?.[KEY_MLS_TRIGGER.zip_code]?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.zip_code, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.zip_code, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col" id="market">
                        <AppSelectV2
                          className="field item-field "
                          label="Property Type"
                          placeholder="Select Property Type"
                          options={arrayStrToValueLabel(options_property_type)}
                          onChange={(value) => onChangeField(KEY_MLS_TRIGGER.property_type, 'temp_value', value)}
                          onBlur={formik.handleBlur}
                          value={formik?.values?.property_type.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          errorMessage={formik?.errors?.property_type?.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.property_type)}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.property_type, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.property_type, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Number of Bedrooms</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.num_of_bedrooms, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.num_of_bedrooms?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="num_of_bedrooms"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.num_of_bedrooms, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik?.values?.num_of_bedrooms?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.num_of_bedrooms)}
                            errorMessage={formik?.errors?.num_of_bedrooms?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.num_of_bedrooms, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.num_of_bedrooms, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Number of Bathrooms</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.num_of_bathrooms, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.num_of_bathrooms?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="num_of_bathrooms"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.num_of_bathrooms, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.num_of_bathrooms.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.num_of_bathrooms)}
                            errorMessage={formik.errors?.num_of_bathrooms?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.num_of_bathrooms, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.num_of_bathrooms, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Estimated Price</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.estimated_price, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.estimated_price?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="estimated_price"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.estimated_price, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.estimated_price?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.estimated_price)}
                            errorMessage={formik.errors.estimated_price?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            prefix="$"
                            maxLength={10}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.estimated_price, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.estimated_price, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="w-full">
                        <AppInputV2
                          isTextArea
                          label="Street Address"
                          name="street_address"
                          className="textArea-field"
                          textAreaProps={{
                            autoSize: false,
                            maxLength: 250,
                            rows: 2,
                            placeholder: 'Enter Street Address',
                          }}
                          size="large"
                          value={formik.values.street_address.temp_value}
                          onChange={(e) => onChangeField(KEY_MLS_TRIGGER.street_address, 'temp_value', e.target.value)}
                          onBlur={formik.handleBlur}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          hasError={hasError(KEY_MLS_TRIGGER.street_address)}
                          errorMessage={formik.errors?.street_address?.temp_value}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.street_address, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.street_address, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field"
                          label="Abbreviated Street Name"
                          name="abbreviated_street_name"
                          type="text"
                          size="large"
                          placeholder="Enter Abbreviated Street Name"
                          onChange={(e) =>
                            onChangeField(KEY_MLS_TRIGGER.abbreviated_street_name, 'temp_value', e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.abbreviated_street_name.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.abbreviated_street_name)}
                          errorMessage={formik.errors.abbreviated_street_name?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.abbreviated_street_name, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.abbreviated_street_name, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field"
                          label="Owner’s First Name"
                          name="owner_first_name"
                          type="text"
                          size="large"
                          placeholder="Enter Owner’s First Name"
                          onChange={(e) =>
                            onChangeField(KEY_MLS_TRIGGER.owner_first_name, 'temp_value', e.target.value)
                          }
                          onBlur={formik.handleBlur}
                          value={formik.values.owner_first_name?.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.owner_first_name)}
                          errorMessage={formik.errors.owner_first_name?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.owner_first_name, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.owner_first_name, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field"
                          label="Owner’s Full Name"
                          name="owner_full_name"
                          type="text"
                          placeholder="Enter Owner’s Full Name"
                          size="large"
                          onChange={(e) => onChangeField(KEY_MLS_TRIGGER.owner_full_name, 'temp_value', e.target.value)}
                          onBlur={formik.handleBlur}
                          value={formik.values.owner_full_name?.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.owner_full_name)}
                          errorMessage={formik.errors.owner_full_name?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.owner_full_name, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.owner_full_name, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Stories</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.stories, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.stories?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="stories"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) => onChangeField(KEY_MLS_TRIGGER.stories, 'temp_value', e.target.value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.stories?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.stories)}
                            errorMessage={formik.errors.stories?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.stories, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.stories, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item1">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Year Built</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators(true))}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.year_built, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.year_built?.operator}
                              disabled={addressesFoundCountLoading || formik.isSubmitting}
                            />
                          </SelectV2WrapperStyled>
                          {getValueField('year_built', 'operator') === 'range' ? (
                            <>
                              <DatePicker
                                css={css`
                                  &.ant-picker {
                                    border-color: ${hasError('year_built')
                                      ? theme.text.errorColor
                                      : theme.text.grayColor}!important;
                                  }
                                `}
                                size="large"
                                name="start"
                                placeholder="YYYY"
                                value={
                                  formik?.values?.year_built?.start ? moment(formik?.values?.year_built?.start) : null
                                }
                                picker="year"
                                onChange={(date, dateString) =>
                                  onChangeField(KEY_MLS_TRIGGER.year_built, 'start', dateString)
                                }
                                inputReadOnly
                                format={'YYYY'}
                                disabled={addressesFoundCountLoading || formik.isSubmitting}
                                getPopupContainer={() => document.getElementById('market') as HTMLElement}
                              />
                              <p className="to">to</p>
                              <DatePicker
                                css={css`
                                  &.ant-picker {
                                    border-color: ${hasError('year_built')
                                      ? theme.text.errorColor
                                      : theme.text.grayColor}!important;
                                  }
                                `}
                                size="large"
                                name="end"
                                placeholder="YYYY"
                                value={formik?.values?.year_built?.end ? moment(formik?.values?.year_built?.end) : null}
                                onChange={(date, dateString) =>
                                  onChangeField(KEY_MLS_TRIGGER.year_built, 'end', dateString)
                                }
                                inputReadOnly
                                format={'YYYY'}
                                picker="year"
                                disabled={addressesFoundCountLoading || formik.isSubmitting}
                                getPopupContainer={() => document.getElementById('market') as HTMLElement}
                              />
                            </>
                          ) : (
                            <DatePicker
                              css={css`
                                &.ant-picker {
                                  border-color: ${hasError('year_built')
                                    ? theme.text.errorColor
                                    : theme.text.grayColor}!important;
                                }
                              `}
                              size="large"
                              name="year_built"
                              placeholder="YYYY"
                              value={
                                formik?.values?.year_built?.temp_value
                                  ? moment(formik?.values?.year_built?.temp_value)
                                  : null
                              }
                              onChange={(date, dateString) => {
                                onChangeField(KEY_MLS_TRIGGER.year_built, 'temp_value', dateString);
                              }}
                              inputReadOnly
                              picker="year"
                              getPopupContainer={() => document.getElementById('market') as HTMLElement}
                              format={'YYYY'}
                              disabled={addressesFoundCountLoading}
                            />
                          )}
                        </div>
                        {hasError(KEY_MLS_TRIGGER.year_built) ? (
                          <ErrorMessage className="form-item-error">{formik.errors.year_built.temp_value}</ErrorMessage>
                        ) : null}
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.year_built, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.year_built, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item2">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Last Sold Date</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators(true))}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.last_sold_date, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.last_sold_date.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          {getValueField('last_sold_date', 'operator') === 'range' ? (
                            <>
                              {' '}
                              <DatePicker
                                css={css`
                                  &.ant-picker {
                                    border-color: ${hasError('last_sold_date')
                                      ? theme.text.errorColor
                                      : theme.text.grayColor}!important;
                                  }
                                `}
                                size="large"
                                name="startDate"
                                placeholder="MM/DD/YYYY"
                                value={formik?.values?.last_sold_date?.start}
                                onChange={(date) => onChangeField(KEY_MLS_TRIGGER.last_sold_date, 'start', date)}
                                inputReadOnly
                                format={formatDate}
                                disabled={addressesFoundCountLoading || formik.isSubmitting}
                                getPopupContainer={() => document.getElementById('market') as HTMLElement}
                              />
                              <p className="to">to</p>
                              <DatePicker
                                size="large"
                                css={css`
                                  &.ant-picker {
                                    border-color: ${hasError('last_sold_date')
                                      ? theme.text.errorColor
                                      : theme.text.grayColor}!important;
                                  }
                                `}
                                name="endDate"
                                placeholder="MM/DD/YYYY"
                                value={formik?.values?.last_sold_date?.end}
                                onChange={(date) => onChangeField(KEY_MLS_TRIGGER.last_sold_date, 'end', date)}
                                inputReadOnly
                                format={formatDate}
                                disabled={addressesFoundCountLoading || formik.isSubmitting}
                                getPopupContainer={() => document.getElementById('market') as HTMLElement}
                              />
                            </>
                          ) : (
                            <DatePicker
                              size="large"
                              name="startDate"
                              css={css`
                                &.ant-picker {
                                  border-color: ${hasError('last_sold_date')
                                    ? theme.text.errorColor
                                    : theme.text.grayColor}!important;
                                }
                              `}
                              placeholder="MM/DD/YYYY"
                              value={formik?.values?.last_sold_date?.temp_value}
                              onChange={(date) => onChangeField(KEY_MLS_TRIGGER.last_sold_date, 'temp_value', date)}
                              inputReadOnly
                              format={formatDate}
                              disabled={addressesFoundCountLoading || formik.isSubmitting}
                              getPopupContainer={() => document.getElementById('market') as HTMLElement}
                            />
                          )}
                        </div>
                        {hasError(KEY_MLS_TRIGGER.last_sold_date) ? (
                          <ErrorMessage className="form-item-error">
                            {formik.errors.last_sold_date?.temp_value}
                          </ErrorMessage>
                        ) : null}
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.last_sold_date, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.last_sold_date, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item3">
                    <div className="flex-col">
                      <div className="feature">
                        <label className="s-label">Features</label>
                        <AppInputV2
                          isTextArea
                          name="features"
                          className="textArea-field h-limit"
                          size="large"
                          textAreaProps={{
                            autoSize: false,
                            maxLength: 250,
                            rows: 2,
                            placeholder: 'Enter Tags separated by commas',
                          }}
                          value={formik.values.features.temp_value}
                          onChange={(e) => onChangeField(KEY_MLS_TRIGGER.features, 'temp_value', e.target.value)}
                          onBlur={formik.handleBlur}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          hasError={hasError(KEY_MLS_TRIGGER.features)}
                          errorMessage={formik.errors.features?.temp_value}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.features, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.features, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item4">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Last Sold Price</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.last_sold_price, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.last_sold_price?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="last_sold_price"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.last_sold_price, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.last_sold_price.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.last_sold_price)}
                            errorMessage={formik.errors?.last_sold_price?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            prefix="$"
                            maxLength={10}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.last_sold_price, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.last_sold_price, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item5">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Years Since Purchase</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) =>
                                onChangeField(KEY_MLS_TRIGGER.year_since_purchase, 'operator', value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.year_since_purchase?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="year_since_purchase"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.year_since_purchase, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.year_since_purchase?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.year_since_purchase)}
                            errorMessage={formik.errors?.year_since_purchase?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.year_since_purchase, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.year_since_purchase, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item6">
                    <div className="flex-col">
                      <div className="flex-col">
                        <AppInputV2
                          className="field sm-field"
                          label="Turn Over Rate"
                          name="turn_over_rate"
                          type="text"
                          placeholder="Enter Amount"
                          size="large"
                          onChange={(e) => onChangeField(KEY_MLS_TRIGGER.turn_over_rate, 'temp_value', e.target.value)}
                          onBlur={formik.handleBlur}
                          value={formik.values.turn_over_rate?.temp_value}
                          hasError={hasError(KEY_MLS_TRIGGER.turn_over_rate)}
                          errorMessage={formik.errors?.turn_over_rate?.temp_value}
                          disabled={addressesFoundCountLoading || formik.isSubmitting}
                          maxLength={6}
                        />
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.turn_over_rate, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.turn_over_rate, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item7">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Avg Years Owned </label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.avg_year_owned, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.avg_year_owned?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="avg_year_owned"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.avg_year_owned, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.avg_year_owned?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.avg_year_owned)}
                            errorMessage={formik.errors?.avg_year_owned?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.avg_year_owned, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.avg_year_owned, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item8">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Square Feet</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.square_feet, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.square_feet?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="square_feet"
                            type="text"
                            size="large"
                            placeholder="Enter Amount"
                            onChange={(e) => onChangeField(KEY_MLS_TRIGGER.square_feet, 'temp_value', e.target.value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.square_feet?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.square_feet)}
                            errorMessage={formik.errors.square_feet?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.square_feet, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.square_feet, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>

                  <div className="form-item-half flex item9">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Years Owned</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.years_owned, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.years_owned?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="years_owned"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) => onChangeField(KEY_MLS_TRIGGER.years_owned, 'temp_value', e.target.value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.years_owned?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.years_owned)}
                            errorMessage={formik.errors.years_owned?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.years_owned, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.years_owned, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item10">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">AVM Value</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.avm_value, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.avm_value?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="avm_value"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) => onChangeField(KEY_MLS_TRIGGER.avm_value, 'temp_value', e.target.value)}
                            onBlur={formik.handleBlur}
                            value={formik.values.avm_value?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.avm_value)}
                            errorMessage={formik.errors.avm_value?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={10}
                            prefix="$"
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.avm_value, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.avm_value, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item11">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Price Per Sqft</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) =>
                                onChangeField(KEY_MLS_TRIGGER.price_per_square_foot, 'operator', value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.price_per_square_foot?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="price_per_square_foot"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.price_per_square_foot, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.price_per_square_foot?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.price_per_square_foot)}
                            errorMessage={formik.errors.price_per_square_foot?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={10}
                            prefix="$"
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.price_per_square_foot, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.price_per_square_foot, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item12">
                    <div className="flex-col">
                      <div className="w-full">
                        <label className="s-label">Days on Market</label>
                        <div className="flex gr-field" id="market">
                          <SelectV2WrapperStyled>
                            <AppSelectV2
                              className="field item-field cal-field mr"
                              options={arrayStrToValueLabel(options_operators())}
                              onChange={(value) => onChangeField(KEY_MLS_TRIGGER.days_on_market, 'operator', value)}
                              onBlur={formik.handleBlur}
                              value={formik.values.days_on_market?.operator}
                              disabled={addressesFoundCountLoading}
                            />
                          </SelectV2WrapperStyled>
                          <AppInputV2
                            className="field sm-field val-field"
                            name="days_on_market"
                            type="text"
                            placeholder="Enter Amount"
                            size="large"
                            onChange={(e) =>
                              onChangeField(KEY_MLS_TRIGGER.days_on_market, 'temp_value', e.target.value)
                            }
                            onBlur={formik.handleBlur}
                            value={formik.values.days_on_market?.temp_value}
                            hasError={hasError(KEY_MLS_TRIGGER.days_on_market)}
                            errorMessage={formik.errors.days_on_market?.temp_value}
                            disabled={addressesFoundCountLoading || formik.isSubmitting}
                            maxLength={6}
                          />
                        </div>
                      </div>
                      <ButtonAdd
                        disabled={addressesFoundCountLoading}
                        handleIncludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.days_on_market, 'temp_value', enumAddType.INCLUDE)
                        }
                        handleExcludeBtn={() =>
                          handleAddInput(KEY_MLS_TRIGGER.days_on_market, 'temp_value', enumAddType.EXCLUDE)
                        }
                      />
                    </div>
                  </div>
                  <div className="form-item-half flex item13">
                    <div className="gr-unit">
                      <div className="flex-col">
                        <div className="w-full">
                          <label className="s-label">Interest Rate</label>
                          <div className="flex gr-field" id="market">
                            <SelectV2WrapperStyled>
                              <AppSelectV2
                                className="field item-field cal-field mr"
                                options={arrayStrToValueLabel(options_operators())}
                                onChange={(value) => onChangeField(KEY_MLS_TRIGGER.interest_rate, 'operator', value)}
                                onBlur={formik.handleBlur}
                                value={formik.values.interest_rates?.operator}
                                disabled={addressesFoundCountLoading}
                              />
                            </SelectV2WrapperStyled>
                            <AppInputV2
                              className="field sm-field val-field"
                              name="interest_rates"
                              type="text"
                              placeholder="Enter Percentage"
                              size="large"
                              onChange={(e) =>
                                onChangeField(KEY_MLS_TRIGGER.interest_rate, 'temp_value', e.target.value)
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.interest_rates?.temp_value}
                              hasError={hasError(KEY_MLS_TRIGGER.interest_rate)}
                              errorMessage={formik.errors.interest_rates?.temp_value}
                              disabled={addressesFoundCountLoading || formik.isSubmitting}
                              maxLength={10}
                              suffix="%"
                            />
                          </div>
                        </div>
                        <ButtonAdd
                          disabled={addressesFoundCountLoading}
                          handleIncludeBtn={() =>
                            handleAddInput(KEY_MLS_TRIGGER.interest_rate, 'temp_value', enumAddType.INCLUDE)
                          }
                          handleExcludeBtn={() =>
                            handleAddInput(KEY_MLS_TRIGGER.interest_rate, 'temp_value', enumAddType.EXCLUDE)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="form-grid-area">

              </div> */}
              </div>
              {/* end House Properties  */}

              <div className="field_item none-border">
                <div className="title">
                  <h2>Drip For Smart Triggers</h2>
                </div>
                <div className="form-grid">
                  <div className="form-item-half"></div>
                  <div className="form-item-half" id="market">
                    <div className="pin-link">
                      <AppSelectV2
                        requiredMark
                        loading={loadingMore}
                        onLoadMore={handleInfiniteLoad}
                        label="Drip For Smart Triggers"
                        options={optionsDripCampaign}
                        fieldNames={{ label: 'name', value: 'id' }}
                        autoClearSearchValue={false}
                        value={formik.values[KEY_MLS_TRIGGER.sm_trigger_campaign_id]}
                        placeholder="Select Drip For Smart Triggers"
                        onSearch={setSearchValueDrip}
                        onChange={(_value, option) => {
                          if (Array.isArray(option)) return;
                          onChangeField(KEY_MLS_TRIGGER.sm_trigger_campaign_id, null, option?.id || '');
                          setSearchValueDrip(option?.name || undefined);
                        }}
                        filterOption={false}
                        showSearch
                        allowClear
                        hasError={hasError(KEY_MLS_TRIGGER.sm_trigger_campaign_id)}
                        errorMessage={formik.errors.sm_trigger_campaign_id}
                      />
                      <div className="drip-pin">
                        {searchValueDrip ? (
                          <Hyperlink
                            classNames="link-redirect"
                            queryParams={{ search: searchValueDrip }}
                            url={`${RoutePaths.DRIP_CAMPAIGNS}/?tab=SMART_DRIP_CAMPAIGN`}
                          >
                            <Tooltip title={'Go to Drip Campaign'} trigger={'hover'} color="#000">
                              <MdOpenInNew title={'Go to Drip Campaign'} />
                            </Tooltip>
                          </Hyperlink>
                        ) : (
                          <Hyperlink
                            classNames="link-redirect add-new"
                            url={`${RoutePaths.DRIP_CAMPAIGNS}/create/?tab=SMART_DRIP_CAMPAIGN`}
                          >
                            <Tooltip title={'Add New Drip Campaign'} trigger={'hover'} color="#000">
                              <MdAdd title="Add New Drip Campaign" />
                            </Tooltip>
                          </Hyperlink>
                        )}
                        <div className="link-redirect refresh">
                          <Tooltip title={'Refresh'} trigger={'hover'} color="#000">
                            <MdOutlineRefresh
                              onClick={() => {
                                if (loadingMore) return;
                                setSearchValueDrip('');
                                onChangeField(KEY_MLS_TRIGGER.sm_trigger_campaign_id, null, '');
                                debounceSearchDripCampaign();
                              }}
                              style={{ animation: loadingMore ? 'spin 1s linear infinite' : 'none' }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-item-half"></div>
                </div>
              </div>
              <div
                className="btn-submit"
                css={css`
                  background: ${theme.color.DARKBLUE};
                  padding: 25px 35px;
                  border-radius: 16px;
                  width: 522px;
                  margin: 0 auto;
                  margin-top: 32px;
                  bottom: -200px;
                  position: absolute;
                  left: 50%;
                  transform: translate(-50%, 0);
                `}
              >
                <AppButtonV2
                  block
                  size="large"
                  width="100%"
                  onClick={() => {
                    const touchedObject = {};
                    Object.keys(formik.errors).forEach((key) => (touchedObject[key] = true));
                    formik.setTouched({ ...formik.touched, ...touchedObject });
                    const isNotValid = isNotValidSubmitting();
                    if (isNotValid) return;
                    formik?.submitForm();
                  }}
                  disabled={addressesFoundCountLoading}
                  htmlType="button"
                  loading={isLoading}
                  style={{ marginBottom: 16 }}
                >
                  {isDuplicateItem ? 'Duplicate' : idEditing ? 'Update' : 'Save'}
                </AppButtonV2>

                <AppButtonV2
                  block
                  color="WhiteTransparent"
                  size="large"
                  width="100%"
                  onClick={handleCancel}
                  disabled={addressesFoundCountLoading || formik.isSubmitting || isLoading}
                >
                  Cancel
                </AppButtonV2>
              </div>
            </form>
          </div>
        </div>
        {resetAddressesFoundCountFilter && visible && (
          <MailingAddressSentForms
            isEditingMLSPopup={!!idEditing}
            mode={ShowAddressesMode.MLS_TRIGGER}
            uniqID={uuidv4()}
          />
        )}
      </div>
    </InnerLoading>
  );
};

export default ModalSmartTriggerForm;

const SwitchStyled = styled.div`
  .ant-radio-button-wrapper {
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    color: #ffff !important;
    border-color: #ff00dd !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::hover,
    &::focus,
    &::active {
      border-color: #ff00dd;
      color: #ffff !important;
    }
    &::before {
      display: none;
    }
    &.ant-radio-button-wrapper-checked {
      background-color: #ff00dd;

      &::hover,
      &::focus,
      &::active {
        border-color: #ff00dd;
        color: #ff00dd !important;
      }
    }
  }
`;
